<template>
  <BodySimple>
    <template #header>
      <h2 class="text-xl font-bold">تعرفه های خدمات و تبلیغات</h2>
      <ul class="list-inside list-disc mt-5">
        <li>
          <router-link :to="{ hash: '#group_ad' }" class="text-sky-500">
            تعرفه تبلیغات سراسری در گروه ها
          </router-link>
        </li>
        <li>
          <router-link :to="{ hash: '#group_bot' }" class="text-sky-500">
            تعرفه ربات مدیر گروه
          </router-link>
        </li>
        <li>
          <router-link :to="{ hash: '#channel_bot' }" class="text-sky-500">
            تعرفه ربات مدیر کانال
          </router-link>
        </li>
      </ul>
    </template>
    <template #default>
      <div
        class="p-3 bg-blueGray-100 border border-blueGray-200 rounded-md"
        id="group_ad"
      >
        <div class="text-lg font-bold flex items-center gap-2">
          <img src="@/assets/images/business-startup.png" class="inline-block h-20" />
          <h2 class="text-lg font-bold">تعرفه تبلیغات سراسری در گروه ها</h2>
        </div>
        <p class="mt-5">
          اگر در مورد این تبلیغات و روش انجام سوالی دارید به بخش سوالات متداول رجوع کنید و
          در صورت پیدا نکردن جواب به پشتیبانی پیام دهید
        </p>
        <ul class="list-inside list-disc mt-5">
          <li>هزینه هر 1000 بازدید معمولی {{ $number.format(3000) }} تومان</li>
          <li>هزینه هر 1000 بازدید مستقیم {{ $number.format(4500) }} تومان</li>
          <li>هزینه هر کلید شیشه ای جدا {{ $number.format(8000) }} تومان</li>
          <li>هزینه هر کلید شیشه ای زیر بنر {{ $number.format(10000) }} تومان</li>
        </ul>
        <h3 class="pt-5 text-lg font-bold">تعرفه های پیشنهادی یک روزه برای بار اول</h3>
        <div class="grid grid-cols-2 sm:grid-cols-4 gap-2 mt-5">
          <div class="p-2 sm:p-4 bg-white shadow-md rounded-md text-center">
            <div class="my-3">10 هزار بازدید معمولی</div>
            <div class="my-3">بدون کلید شیشه‌ای</div>
            <div class="my-3 text-lg font-bold text-green-600">
              {{ $number.format(30000) }} تومان
            </div>
          </div>
          <div class="p-2 sm:p-4 bg-white shadow-md rounded-md text-center">
            <div class="my-3">10 هزار بازدید معمولی</div>
            <div class="my-3">2 کلید شیشه‌ای</div>
            <div class="my-3 text-lg font-bold text-green-600">
              {{ $number.format(46000) }} تومان
            </div>
          </div>
          <div class="p-2 sm:p-4 bg-white shadow-md rounded-md text-center">
            <div class="my-3">20 هزار بازدید معمولی</div>
            <div class="my-3">بدون کلید شیشه‌ای</div>
            <div class="my-3 text-lg font-bold text-green-600">
              {{ $number.format(60000) }} تومان
            </div>
          </div>
          <div class="p-2 sm:p-4 bg-white shadow-md rounded-md text-center">
            <div class="my-3">20 هزار بازدید معمولی</div>
            <div class="my-3">2 کلید شیشه‌ای</div>
            <div class="my-3 text-lg font-bold text-green-600">
              {{ $number.format(76000) }} تومان
            </div>
          </div>
        </div>
        <p class="mt-5">
          اگر برای اولین بار میخواهید تبلیغات سفارش دهید پیشنهاد ما یکی از تعرفه های بالا
          است اما تعرفه های بند مدت با بازدید بیشتر هم ارائه می‌دهیم که در زیر چند نمونه
          پیشنهادی از آنها را معرفی مشاهده می‌کنید
        </p>
        <div class="grid grid-cols-2 sm:grid-cols-4 gap-2 mt-5">
          <div class="p-2 sm:p-4 bg-white shadow-md rounded-md text-center">
            <div class="text-lg font-bold">هفتگی</div>
            <div class="my-3">هر روز 10 هزار بازدید معمولی</div>
            <div class="my-3 text-lg font-bold">
              <div>بدون کلید</div>
              <div class="text-green-600">{{ $number.format(210000) }} تومان</div>
            </div>
            <div class="my-3 text-lg font-bold">
              <div>با 2 کلید</div>
              <div class="text-green-600">{{ $number.format(322000) }} تومان</div>
            </div>
          </div>

          <div class="p-2 sm:p-4 bg-white shadow-md rounded-md text-center">
            <div class="text-lg font-bold">هفتگی</div>
            <div class="my-3">هر روز 20 هزار بازدید معمولی</div>
            <div class="my-3 text-lg font-bold">
              <div>بدون کلید</div>
              <div class="text-green-600">{{ $number.format(420000) }} تومان</div>
            </div>
            <div class="my-3 text-lg font-bold">
              <div>با 2 کلید</div>
              <div class="text-green-600">{{ $number.format(532000) }} تومان</div>
            </div>
          </div>

          <div class="p-2 sm:p-4 bg-white shadow-md rounded-md text-center">
            <div class="text-lg font-bold">30 روزه</div>
            <div class="my-3">هر روز 10 هزار بازدید معمولی</div>
            <div class="my-3 text-lg font-bold">
              <div>بدون کلید</div>
              <div class="text-green-600">{{ $number.format(900000) }} تومان</div>
            </div>
            <div class="my-3 text-lg font-bold">
              <div>با 2 کلید</div>
              <div class="text-green-600">{{ $number.format(1380000) }} تومان</div>
            </div>
          </div>

          <div class="p-2 sm:p-4 bg-white shadow-md rounded-md text-center">
            <div class="text-lg font-bold">30 روزه</div>
            <div class="my-3">هر روز 20 هزار بازدید معمولی</div>
            <div class="my-3 text-lg font-bold">
              <div>بدون کلید</div>
              <div class="text-green-600">{{ $number.format(1800000) }} تومان</div>
            </div>
            <div class="my-3 text-lg font-bold">
              <div>با 2 کلید</div>
              <div class="text-green-600">{{ $number.format(2280000) }} تومان</div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="p-3 bg-blueGray-100 border border-blueGray-200 rounded-md mt-5"
        id="group_bot"
      >
        <div class="text-lg font-bold flex items-center gap-2">
          <img src="@/assets/images/icons8-bot-96.png" class="inline-block h-20" />
          <h2 class="text-lg font-bold">تعرفه ربات مدیریت گروه</h2>
        </div>
        <p class="mt-5">
          ربات به صورت پیشفرض رایگان است و می‌توانید به طور رایگان از آن استفاده کنید.
          البته در این صورت به گروه شما تبلیغات ارسال می‌کند. برای حذف تبلیغات یا بعضی از
          امکانات ویژه باید آن را خریداری کنید
        </p>
        <div class="grid grid-cols-2 sm:grid-cols-4 gap-2 mt-5">
          <div class="p-2 sm:p-4 bg-white shadow-md rounded-md text-center">
            <img
              src="@/assets/images/green-book_1f4d7.png"
              class="inline-block h-20 mb-3"
            />
            <div class="text-lg font-bold">سبز</div>
            <div class="my-3">بدون تبلیغ</div>
            <div class="my-3 text-lg font-bold">
              <div>1 ماهه</div>
              <div class="text-green-600">{{ $number.format(15000) }} تومان</div>
            </div>
            <div class="my-3 text-lg font-bold">
              <div>3 ماهه</div>
              <div class="text-green-600">{{ $number.format(43000) }} تومان</div>
            </div>
            <div class="my-3 text-lg font-bold">
              <div>6 ماهه</div>
              <div class="text-green-600">{{ $number.format(85000) }} تومان</div>
            </div>
          </div>

          <div class="p-2 sm:p-4 bg-white shadow-md rounded-md text-center">
            <img
              src="@/assets/images/blue-book_1f4d8.png"
              class="inline-block h-20 mb-3"
            />
            <div class="text-lg font-bold">آبی</div>
            <div class="my-3">امکانات ویژه</div>
            <div class="my-3 text-lg font-bold">
              <div>1 ماهه</div>
              <div class="text-green-600">{{ $number.format(15000) }} تومان</div>
            </div>
            <div class="my-3 text-lg font-bold">
              <div>3 ماهه</div>
              <div class="text-green-600">{{ $number.format(43000) }} تومان</div>
            </div>
            <div class="my-3 text-lg font-bold">
              <div>6 ماهه</div>
              <div class="text-green-600">{{ $number.format(85000) }} تومان</div>
            </div>
          </div>

          <div class="p-2 sm:p-4 bg-white shadow-md rounded-md text-center">
            <img
              src="@/assets/images/3rd-place-medal_1f949.png"
              class="inline-block h-20 mb-3"
            />
            <div class="text-lg font-bold">برنزی</div>
            <div class="my-3">امکانات ویژه و بدون تبلیغ</div>
            <div class="my-3 text-lg font-bold">
              <div>1 ماهه</div>
              <div class="text-green-600">{{ $number.format(25000) }} تومان</div>
            </div>
            <div class="my-3 text-lg font-bold">
              <div>3 ماهه</div>
              <div class="text-green-600">{{ $number.format(73000) }} تومان</div>
            </div>
            <div class="my-3 text-lg font-bold">
              <div>6 ماهه</div>
              <div class="text-green-600">{{ $number.format(145000) }} تومان</div>
            </div>
          </div>

          <div class="p-2 sm:p-4 bg-white shadow-md rounded-md text-center">
            <img
              src="@/assets/images/2nd-place-medal_1f948.png"
              class="inline-block h-20 mb-3"
            />
            <div class="text-lg font-bold">نقره‌ای</div>
            <div class="my-3">برنزی + پشتیبانی</div>
            <div class="my-3 text-lg font-bold">
              <div>3 ماهه</div>
              <div class="text-green-600">{{ $number.format(150000) }} تومان</div>
            </div>
            <div class="my-3 text-lg font-bold">
              <div>6 ماهه</div>
              <div class="text-green-600">{{ $number.format(295000) }} تومان</div>
            </div>
            <div class="my-3 text-lg font-bold">
              <div>12 ماهه</div>
              <div class="text-green-600">{{ $number.format(585000) }} تومان</div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="p-3 bg-blueGray-100 border border-blueGray-200 rounded-md mt-5"
        id="channel_bot"
      >
        <div class="text-lg font-bold flex items-center gap-2">
          <img src="@/assets/images/icons8-bot-96-blue.png" class="inline-block h-20" />
          <h2 class="text-lg font-bold">تعرفه ربات مدیریت کانال</h2>
        </div>
        <p class="mt-5">
          ربات به صورت پیشفرض رایگان است و می‌توانید به طور رایگان از آن استفاده کنید.
          البته در این صورت به گروه شما تبلیغات ارسال می‌کند. برای حذف تبلیغات باید آن را
          خریداری کنید
        </p>
        <div class="grid grid-cols-2 sm:grid-cols-4 gap-2 mt-5">
          <div class="p-2 sm:p-4 bg-white shadow-md rounded-md text-center">
            <img
              src="@/assets/images/green-book_1f4d7.png"
              class="inline-block h-20 mb-3"
            />
            <div class="text-lg font-bold">سبز</div>
            <div class="my-3">بدون تبلیغات</div>
            <div class="my-3 text-lg font-bold">
              <div>1 ماهه</div>
              <div class="text-green-600">{{ $number.format(25000) }} تومان</div>
            </div>
            <div class="my-3 text-lg font-bold">
              <div>3 ماهه</div>
              <div class="text-green-600">{{ $number.format(73000) }} تومان</div>
            </div>
            <div class="my-3 text-lg font-bold">
              <div>6 ماهه</div>
              <div class="text-green-600">{{ $number.format(145000) }} تومان</div>
            </div>
          </div>

          <div class="p-2 sm:p-4 bg-white shadow-md rounded-md text-center">
            <img
              src="@/assets/images/blue-book_1f4d8.png"
              class="inline-block h-20 mb-3"
            />
            <div class="text-lg font-bold">آبی</div>
            <div class="my-3">امکانات ویژه</div>
            <div class="my-3 text-lg font-bold">
              <div>1 ماهه</div>
              <div class="text-green-600">{{ $number.format(25000) }} تومان</div>
            </div>
            <div class="my-3 text-lg font-bold">
              <div>3 ماهه</div>
              <div class="text-green-600">{{ $number.format(73000) }} تومان</div>
            </div>
            <div class="my-3 text-lg font-bold">
              <div>6 ماهه</div>
              <div class="text-green-600">{{ $number.format(145000) }} تومان</div>
            </div>
          </div>

          <div class="p-2 sm:p-4 bg-white shadow-md rounded-md text-center">
            <img
              src="@/assets/images/3rd-place-medal_1f949.png"
              class="inline-block h-20 mb-3"
            />
            <div class="text-lg font-bold">برنزی</div>
            <div class="my-3">امکانات ویژه و بدون تبلیغ</div>
            <div class="my-3 text-lg font-bold">
              <div>1 ماهه</div>
              <div class="text-green-600">{{ $number.format(45000) }} تومان</div>
            </div>
            <div class="my-3 text-lg font-bold">
              <div>3 ماهه</div>
              <div class="text-green-600">{{ $number.format(132000) }} تومان</div>
            </div>
            <div class="my-3 text-lg font-bold">
              <div>6 ماهه</div>
              <div class="text-green-600">{{ $number.format(265000) }} تومان</div>
            </div>
          </div>

          <div class="p-2 sm:p-4 bg-white shadow-md rounded-md text-center">
            <img
              src="@/assets/images/2nd-place-medal_1f948.png"
              class="inline-block h-20 mb-3"
            />
            <div class="text-lg font-bold">نقره‌ای</div>
            <div class="my-3">برنزی + پشتیبانی</div>
            <div class="my-3 text-lg font-bold">
              <div>3 ماهه</div>
              <div class="text-green-600">{{ $number.format(222000) }} تومان</div>
            </div>
            <div class="my-3 text-lg font-bold">
              <div>6 ماهه</div>
              <div class="text-green-600">{{ $number.format(440000) }} تومان</div>
            </div>
            <div class="my-3 text-lg font-bold">
              <div>12 ماهه</div>
              <div class="text-green-600">{{ $number.format(850000) }} تومان</div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </BodySimple>
</template>

<script>
import BodySimple from "@/layouts/BodySimple.vue";
export default {
  components: { BodySimple },
};
</script>
